import { Link } from "gatsby";
import React from "react";
import JoinCS from "../components/JoinCS";
import PageWrapper from "../components/PageWrapper";
import AboutCareer from "../sections/careers/javascript-developer/AboutCareer";
import Details from "../sections/careers/javascript-developer/Details";
import CtaSec from "../sections/homepage/cta";

const JavascriptDeveloper = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <Link to="/contact">
                <button className="btn btn-fox-violet header-btn-2 font-size-3 rounded-5">
                  Let's talk
                </button>
              </Link>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <div className="container pt-24 pb-14 pb-md-18 pb-lg-24">
          <div className="col-lg-9 col-md-9 col-xs-12">
            <Link to="/careers" className="font-size-4 pb-4 text-blue m-3">
              ← BACK TO CAREERS PAGE
            </Link>
            <h1 className="font-size-10 pb-4">
              🌍 JavaScript Developer <br/> (Chrome Extension Specialist)
            </h1>
            <div className="">
              <p className="font-size-6 d-flex align-items-center m-2">
                📍 Location: Remote / On-site
              </p>
              <p className="font-size-6 d-flex align-items-center m-2">
                📅 Employment Type: Full-time
              </p>
            </div>
            <Link
              className="mb-1 mt-6"
              href="mailto:career@cloudstackai.com"
              role="button"
              aria-expanded="false"
            >
              <button className="btn btn-fox-violet header-btn-2 font-size-3 rounded-5">
                Apply now
              </button>
            </Link>
            <AboutCareer />
            <div className="mt-10 pt-8">
              <Details />
            </div>
          </div>
          <JoinCS title="Growth Strategist and Innovation Architect" />
          <div
            className="border-radius-sm border rounded shadow-2 p-10 m-5 mt-7 mb-7"
            data-aos="zoom-in"
            data-aos-duration={300}
            data-aos-delay={300}
          >
            <h4 className="font-size-9 text-center p-4 mt-5">
              Be part of something bigger.
            </h4>
            <p className="font-size-6 mt-1 text-center">
              Join Cloudstack, and let’s build the future together! <br />
              Ready to Apply? Send your resume to{" "}
              <Link
                className="mb-1 mt-8"
                href="mailto:career@cloudstackai.com"
                role="button"
                aria-expanded="false"
              >
                career@cloudstackai.com
              </Link>
            </p>
          </div>
        </div>
        <CtaSec />
      </PageWrapper>
    </>
  );
};
export default JavascriptDeveloper;
