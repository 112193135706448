import React from "react";
const Details = () => {
  return (
    <div className="container" data-aos="fade-up" data-aos-duration={600}>
      <div className="">
        <h2 className="font-size-9 mb-4 pb-3">What You’ll Do</h2>
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Develop and maintain <b>scalable Chrome extensions.</b>
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Work with{" "}
            <b>browser APIs, background scripts, and messaging protocols</b>
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Optimize extensions for
            <b>speed, performance, and efficiency.</b>
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Debug, test, and enhance extensions to improve user experience.
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Integrate with OAuth, APIs, and third-party services.
          </li>
        </ul>

        <h2 className="font-size-9 mb-4 p-3">What We’re Looking For</h2>
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Strong knowledge of
            <b>JavaScript, HTML, and CSS.</b>
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Experience with
            <b>
              Chrome Extensions API, background scripts, and content scripts.
            </b>
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ Familiarity with
            <b>OAuth, API integrations, and local/browser storage.</b>
          </li>
          <li className="font-size-6 line-height-28 mb-0 pr-sm-10 pr-md-0 pr-xs-17 pr-lg-8 pr-xl-5">
            ✔ A problem-solver with
            <b>a passion for writing clean, efficient, and scalable code.</b>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Details;
